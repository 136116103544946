<template>
    <!-- 展示搜索到的数据 -->
    <div class="actionFrom">
      <a-spin class="isLoading" :spinning="formLoading" />
      <Theme
        :fromShowDatas="fromShowDatas"
        :getFindDatas="getFindDatas"
        :distinguish = "distinguish"
        :form="form"
        :config="form.config"
        :lookForm="true"
        @submit="saveData"
        v-if="!formLoading"
      />
    </div>
  </template>
  
  <script>
  import { getForm, addFormData, searchData } from '@/services/form'
  import Theme from './components/Theme.vue'
  export default {
    name: 'showDatas',
    components: { Theme },
    data() {
      return {
        modelArr: [],  //存储可编辑的字段model

        getFindDatas: [],  //存储查询后的结果

        distinguish: '',  //区分是查询表单(1)还是编辑表单(2)
        fromShowDatas: '',

        form: [],
        formLoading: false,
        company_uuid: '',
        uuid: ''
      }
    },
    created() {
      this.fromShowDatas = '1'

      this.distinguish = '2'

      const { company_uuid, uuid } = this.$route.params
      this.company_uuid = company_uuid
      localStorage.setItem('company_uuid', company_uuid)
      this.uuid = uuid
      this.init()

      this.getFindDatas = this.$store.state.datas.searchDatas

      // 获取浏览器地址栏，支持用户在浏览器地址栏拼接参数，完成查询数据的功能
      let clickFindBtn = this.$store.state.datas.clickFindBtn
      if (!clickFindBtn) {
        this.GetQueryString()
      }
      
    },
    methods: {

      // 获取浏览器地址栏
      GetQueryString() {
        console.log('地址栏传参')

        // 将地址栏的参数转化为js对象
        let url=window.location.href
        let jsonList={}
        if(url.indexOf("?")>-1){
          var str=window.decodeURIComponent(url.slice(url.indexOf("?")+1))
          var strs=str.split("&")
          for(var i=0;i<strs.length;i++){
            jsonList[strs[i].split("=")[0]]=strs[i].split("=")[1]
          }
        }

        // 调用搜索接口，获取搜索后的数据
        searchData(
        {
          company_uuid: this.company_uuid,
          uuid: this.uuid
        },
          jsonList
        ).then((res) => {
        let {data, msg} = res.data
        if (data.length === 0) {
          this.$message.error(msg)
        }
        this.getFindDatas = data
       })
    },

      // 数据初始化
      init() {
        this.formLoading = true
        getForm({ company_uuid: this.company_uuid, uuid: this.uuid })
          .then((res) => {
            let { data } = res

            if (Array.isArray(data.edit) && data.edit.length > 0) {
                data.edit.forEach( item => {
                    this.modelArr.push(item.options.model)
                })
            }
            data.elements.forEach( item => {
                item.options.disabled = true
                if (this.modelArr.indexOf(item.options.model) !== -1) {
                    item.options.disabled = false  //只有edit字段出现的字段可编辑
                }
            })
            
            
            this.form = data
            this.formLoading = false
            document.title = data.config.title
          })
          .catch(() => {
            this.$message.error('地址错误！')
          })
      },

      saveData(data) {
        console.log('保存传递的参数', data)
        addFormData(
          {
            company_uuid: this.company_uuid,
            uuid: this.uuid
          },
          data
        )
          .then((res) => {
            const { code } = res.data
            if (code === 200) {
              this.$message.success('添加成功！')
              setTimeout(() => {
                this.$router.go(-1)
              }, 500)
            } else {
              this.$message.error('添加失败！')
            }
          })
          .catch(() => {
            this.$message.error('系统内容错误！')
          })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .actionFrom {
    width: 100%;
    height: 100%;
  
    .content {
      min-height: 100vh;
    }
  }
  </style>
  